import React from 'react';
import { graphql } from 'gatsby';
import { Link } from 'gatsby';
import Layout from '../components/layout';
import SEO from '../components/seo';
import PageContent from '../components/pageContent';
import './projectTemplate.scss';
import StickyTitle from '../components/ContentfulStickyTitle';

// const getPreviousProject = (projects, slug) => {
//   for (let i = 0; i < projects.length; i++) {
//     if (projects[i].slug === slug) {
//       return i - 1 >= 0 ? projects[i - 1] : null;
//     }
//   }
// };

const getNextProject = (projects, slug) => {
  for (let j = 0; j < projects.length; j++) {
    if (projects[j].slug === slug) {
      return j + 1 < projects.length ? projects[j + 1] : null;
    }
  }
};

const Project = ({ data }) => {
  const {
    slug,
    projectTitle,
    projectImage,
    featureParagraph,
  } = data.contentfulProjects;

  const projects = data.contentfulAllProjects;

  //const previousProject = getPreviousProject(projects.projects, slug);
  const nextProject = getNextProject(projects.projects, slug);
  return (
    <Layout>
      <SEO title={projectTitle} />
      <StickyTitle title={projectTitle}></StickyTitle>
      <section className="padding-section">
        <div className="project col-xs-12 col-sm-11 col-lg-10 offset-sm-1 pr-0 pr-sm-1">
          <div className="row">
            <h1 className="headline-1 col-xs-12 col-sm-6">{projectTitle}</h1>
          </div>
          <div className="row">
            <div className="col-xs-12 col-sm-6 offset-sm-1 order-sm-2 pr-0 pr-sm-1">
              <img alt={projectImage.title} src={projectImage.file.url} />
            </div>
            <div className="col-xs-12 col-sm-5 order-sm-1">
              <p className="lead-text">{featureParagraph.featureParagraph}</p>
            </div>
          </div>
        </div>
      </section>
      <PageContent
        pageContent={data.contentfulProjects.pageContent}
      ></PageContent>

      <section className="project navigation-links">
        <div className="col-xs-12">
          {nextProject && (
            <Link
              className="navigation-link"
              to={`/projects/${nextProject.slug}`}
              title={`Next project: ${nextProject.projectTitle}`}
            >
              Next Project
              <span> {nextProject.projectTitle}</span>
            </Link>
          )}
        </div>
      </section>
    </Layout>
  );
};
export default Project;
export const pageQuery = graphql`
  query($slug: String!) {
    contentfulProjects(slug: { eq: $slug }) {
      slug
      seoImage {
        file {
          url
        }
        title
      }
      seoDescription {
        seoDescription
      }
      projectTitle
      projectImage {
        file {
          url
        }
        title
      }
      id
      projectCategories
      featureParagraph {
        featureParagraph
      }
      pageContent {
        __typename
        ... on Node {
          ... on ContentfulFullWidthParagraphWithTitle {
            internal {
              type
            }
            paragraph {
              paragraph
              childMarkdownRemark {
                html
              }
            }
            title
            showParagraphTitle
          }
          ... on ContentfulParagraphWithTitleAndSideImage {
            internal {
              type
            }
            paragraph {
              paragraph
              childMarkdownRemark {
                html
              }
            }
            title
            picture {
              file {
                url
              }
              title
            }
          }
          ... on ContentfulFullWidthPicture {
            internal {
              type
            }
            picture {
              file {
                url
              }
              title
            }
          }
          ... on ContentfulFullWidthPictureWithMargins {
            internal {
              type
            }
            picture {
              file {
                url
              }
              title
            }
          }
          ... on ContentfulParagraph {
            internal {
              type
            }
            paragraph {
              paragraph
              childMarkdownRemark {
                html
              }
            }
          }
          ... on ContentfulParagraphWithTitle {
            internal {
              type
            }
            paragraph {
              paragraph
              childMarkdownRemark {
                html
              }
            }
            title
          }
          ... on ContentfulSelectedProjects {
            internal {
              type
            }
            showSeeAllProjects
            projects {
              slug
              projectCategories
              projectTitle
              projectImage {
                file {
                  url
                }
                title
              }
              shortDescription {
                shortDescription
                childMarkdownRemark {
                  html
                }
              }
            }
          }
          ... on ContentfulAllProjects {
            internal {
              type
            }
            projects {
              slug
              projectCategories
              projectTitle
              projectImage {
                file {
                  url
                }
                title
              }
              shortDescription {
                shortDescription
                childMarkdownRemark {
                  html
                }
              }
            }
          }
          ... on ContentfulTwoPicturesCollage {
            internal {
              type
            }
            pictureOne {
              file {
                url
              }
              title
            }
            pictureTwo {
              file {
                url
              }
              title
            }
          }
          ... on ContentfulButton {
            internal {
              type
            }
            buttonName
            buttonUrl
          }
          ... on ContentfulWhatILove {
            internal {
              type
            }
            title1
            title2
            title3
            image1 {
              file {
                url
              }
              title
            }
            image2 {
              file {
                url
              }
              title
            }
            image3 {
              file {
                url
              }
              title
            }
            description1 {
              description1
              childMarkdownRemark {
                html
              }
            }
            description2 {
              description2
              childMarkdownRemark {
                html
              }
            }
            description3 {
              description3
              childMarkdownRemark {
                html
              }
            }
          }
        }
      }
    }

    contentfulAllProjects {
      projects {
        slug
        projectTitle
      }
    }
  }
`;
